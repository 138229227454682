<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;">
    <div>
      <Breadcrumbs />

      <v-menu
          v-model="showColumnsMenu"
          :close-on-content-click="false"
          :nudge-width="450"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-5 inlineblock" v-bind="attrs" v-on="on" icon
              ><v-icon color="grey">mdi-cog-outline</v-icon></v-btn
            >
          </template>
          <v-card style="overflow:hidden;">
            <v-chip
              style="width:225px; margin:15px;"
              :color="allColumnsDisplayed ? 'primary' : 'grey'"
              @click="toggleAllColumns"
              text-color="white"
            >
              <v-avatar v-if="allColumnsDisplayed" left>
                <v-icon>mdi-check</v-icon>
              </v-avatar>
              {{ $t("filters.allfilters") }}
            </v-chip>
            <v-row no-gutters style="margin-right:50px;">
              <v-col cols="6">
                <v-list>
                  <v-list-item v-for="(column, index) in columns.slice(0, columns.length / 2)" :key="index">
                    <template>
                      <v-chip
                        style="width:225px;"
                        :color="column.active ? 'primary' : 'grey'"
                        @click="column.active = !column.active"
                        text-color="white"
                      >
                        <v-avatar v-if="column.active" left>
                          <v-icon>mdi-check</v-icon>
                        </v-avatar>
                        {{ column.title }}
                      </v-chip>
                    </template>
                  </v-list-item>
                </v-list>
              </v-col>

              <v-col no-gutters cols="6">
                <v-list>
                  <v-list-item v-for="(column, index) in columns.slice(columns.length / 2, columns.length)" :key="index">
                    <template>
                      <v-chip
                        style="width:225px;"
                        :color="column.active ? 'primary' : 'grey'"
                        @click="column.active = !column.active"
                        text-color="white"
                      >
                        <v-avatar v-if="column.active" left>
                          <v-icon>mdi-check</v-icon>
                        </v-avatar>
                        {{ column.title }}
                      </v-chip>
                    </template>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-btn icon absolute top right @click="showColumnsMenu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card>
      </v-menu>

      <v-text-field
        class="ml-3 mt-7"
        v-model="generalSearch"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        hide-details
        dense
        filled
        clearable
        :loading="isSearching"
        @keydown="handleSearch"
        @click:clear="handleSearch"
        style="display:inline-block;min-width:400px;"
      ></v-text-field>
    </div>

    <div class="top-right-fab" v-if="userFunctions && userFunctions.find(x => x.id === FUNCTIONS.ACCESS_TO_ALL_DEALERS)">
      <v-btn class="techno-green" fab @click="create">
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </div>

    <v-container :loading="!dealers" fluid>

      <div v-if="!dealers">
        <v-row>
          <v-col cols="12">
            <v-card class="border10">
              <v-skeleton-loader
                type="table-heading, divider, table-heading, divider, table-heading, divider"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-show="dealers">
        <v-row>
          <v-col cols="12">
            <v-card class="border10">
              <div ref="table"></div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import "tabulator-tables/dist/css/tabulator_bootstrap3.min.css";
import { mapMutations, mapActions, mapState } from "vuex";

import UserService from "@/services/user.service";
import Breadcrumbs from "@/components/Breadcrumbs";
import { FUNCTIONS } from "@/enums";
import { getLanguage, getLanguageWithoutRegion, getTabulatorLangsObject, objectMatchSearch, displayPaginationInfo } from "@/utils/utils";

var table = null;

export default {
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      dealers: null,
      locale: this.$i18n.locale,
      searchInput: "",
      showColumnsMenu: false,
      selected: [],
      tabulatorColumns: [],
      columns: [
        {
          field: "name",
          title: this.$t("users.name"),
          active: true,
        },
        {
          field: "phoneMobile",
          title: this.$t("user.phone_mobile"),
          active: true,
        },
        {
          field: "phoneOffice",
          title: this.$t("user.phone_office"),
          active: true,
        },
        {
          field: "emailMain",
          title: this.$t("user.main_email"),
          active: true,
        },
        {
          field: "emailOther",
          title: this.$t("user.other_email"),
          active: false,
        },
        {
          field: "emailPersonal",
          title: this.$t("user.personal_email"),
          active: false,
        },
        {
          field: "number",
          title: this.$t("user.number"),
          active: true,
        },
      ],
      FUNCTIONS: FUNCTIONS,
      generalSearch: '',
      isSearching: false
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      userFunctions: (state) => state.userFunctions
    }),
    allColumnsDisplayed() {
      for (let column of this.columns) {
        if (!column.active) {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    showColumnsMenu() {
      if (!this.showColumnsMenu) {
        this.updateTabulatorColumns();
        table.setColumns(this.tabulatorColumns);

        this.refreshDealers();
      }
    },
  },
  methods: {
    ...mapMutations({
      openToast: "openToast",
    }),
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
      logout: 'logout'
    }),
    async create() {
      const scope = this;

      let data = { locale: this.locale };

      let response = await UserService.createDealer(data);
      if (response.status == 200) {
        scope.$router.push("/dealer/" + response.data.id);
      } else {
        this.openToast({ message: response, duration: 15000, color: "red" });
      }
    },
    updateTabulatorColumns() {
      this.tabulatorColumns = this.columns.filter(c => c.active);

      let displayedColumnKeys = this.tabulatorColumns.map(c => c.field);
      localStorage.setItem('displayedDealersColumnKeys', displayedColumnKeys);
    },
    toggleAllColumns() {
      let allColumnsDisplayed = !this.allColumnsDisplayed;

      this.columns.forEach((column) => {
        column.active = allColumnsDisplayed;
      });
    },
    selectDealer(dealer) {
      this.$router.push("/dealer/" + dealer.id);
    },
    getDealers() { 
      var scope = this;
      return new Promise((resolve, reject) => {

         UserService.getDealers(getLanguage())
         .then((response) => {
           scope.SET_DEALERS(response.data)
           resolve()
         }).catch((err) => {
           reject(err)
           
           if (err.toString().includes("401")) {
            this.logout(this.$socket);
          }
         });
      });
    },
    SET_DEALERS(value) {
      this.dealers = value;
      console.log('DEALERS', this.dealers)
      table.setData(this.dealers);
    },
    initTabulator() {

      var scope = this;

      let columnsLangData = {
        "name": this.$t("users.name"),
        "phoneMobile": this.$t("user.phone_mobile"),
        "phoneOffice": this.$t("user.phone_office"),
        "emailMain": this.$t("user.main_email"),
        "emailOther": this.$t("user.other_email"),
        "emailPersonal": this.$t("user.personal_email"),
        "number": this.$t("user.number"),
      };

      table = new Tabulator(this.$refs.table, {
        layout: "fitColumns",
        columns: this.tabulatorColumns,
        persistence: { sort: true, filter: true, columns: false },
        persistenceID: "persistenceDealersReport",
        pagination: true,
        paginationSize: 10,
        paginationSizeSelector: [10, 25, 50, 100],
        paginationCounter: (pageSize, currentRow, currentPage, totalRows, totalPages) => displayPaginationInfo(pageSize, currentRow, currentPage, totalRows, totalPages, scope),
        locale: getLanguageWithoutRegion(getLanguage()),
        langs: getTabulatorLangsObject(this.$i18n, columnsLangData)
      });

      table.on("rowClick", function (e, row) {
        scope.$router.push("/dealer/" + row.getData().id);
      });

      table.on("tableBuilt", function(){
        scope.getDealers();
      });

    },
    handleSearch() {
      let scope = this;
      this.isSearching = true;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.clearSearch();
      }, 1000);
    },
    clearSearch() {
      this.isSearching = false;
      
      this.refreshDealers();
    },
    refreshDealers() {
      let dealers = this.dealers;
      if (this.generalSearch) {
        dealers = dealers.filter(u => objectMatchSearch(u, this.generalSearch, this.tabulatorColumns.map(c => c.field)));
      }
      table.setData(dealers);
    }
  },
  mounted() {
    console.log("Dealers mounted");
    this.setBreadcrumbs([{ text: this.$t("dealers.title"), href: "/dealers" }]);

    let displayedColumnKeys = localStorage.getItem('displayedDealersColumnKeys');
    if (displayedColumnKeys) {
      displayedColumnKeys = displayedColumnKeys.split(',');
      for (let column of this.columns) {
        column.active = displayedColumnKeys.find(k => column.field == k) != undefined;
      }
    }
    this.updateTabulatorColumns();
    this.initTabulator();
  },
  created: function() {
    console.log("Dealers created");
  },
};
</script>

<style>
.badge-right-top {
  position: absolute!important;
  right: 20px;
  top: 20px;
}
.inlineblock {
  display: inline-block;
}
.v-badge--tile .v-badge__badge {
  border-radius: 3px;
}
.v-breadcrumbs {
  display: inline-block;
}
</style>
